/* INTRO */
.navItem img {
  width: 280px;
  height: auto;
  margin: 40px;
}
.searchButton {
  background-color: #ed227c;
  color: #ffffff;
  border-radius: 0 10px 10px 0;
  width: 60px;
  border: none;
  height: 30px;
  padding-top: 3px;
}
.searchButton:hover {
  filter: brightness(90%);
}

.searchinput {
  border-color: #15b3bd;
  border-radius: 10px 0 0 10px;
  color: #000000;
  border-right: none;
}
.marginsearch {
  margin-bottom: 4px;
}

.navContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.navItem {
  margin: 0 10px;
}

.navItem span {
  font-weight: bold;
}

.btn-cart {
  background-color: #ed227c;
  width: 160px;
  height: 40px;
  font-weight: bold;
  color: #fff;
  border-radius: 10px;
  border: none;
}
.btn-cart:hover {
  filter: brightness(90%);
}
.btn-removecart:active {
  filter: brightness(80%);
}
.carticon {
  margin-right: 12px;
}

@media (max-width: 768px) {
  .navItem img {
    width: 100%;
    margin: 10px;
  }

  .search {
    display: flex;
    align-items: center;
  }

  .searchinput {
    height: 48px;
    width: 320px;
  }

  .searchButton {
    width: calc(100% - 320px);
    height: 48px;
    padding-bottom: 3px;
  }

  .searchButton img {
    width: 16px;
  }

  .navContainer {
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }

  .navItem {
    margin: 10px 0;
  }

  .btn-cart {
    width: 100%;
    font-size: 12px;
    border-radius: 10px;
  }

  .navItem {
    order: 1;
  }

  .search {
    order: 2;
    margin-bottom: 10px;
  }
  .btn-cart {
    order: 3;
    font-size: 20px;
  }
  .carticon {
    width: 32px;
    height: 32px;
  }
}
/* END OF INTRO */

/* NAVIGATION */

.custom-navbar {
  width: 1300px;
  border-radius: 12px;
  margin: auto;
  background-color: #0c1439;
  max-width: 90%;
}
.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='white' stroke-width='3' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
}
.navbar-toggler {
  border: none !important;
}
.custom-navbar .nav-item .nav-link,
.custom-navbar .navbar-nav .nav-link {
  color: white;
}

.custom-navbar .nav-item:hover .nav-link,
.custom-navbar .navbar-nav .nav-item.show .nav-link {
  color: white;
  background-color: #090e28;
}

.custom-navbar .navbar-nav .nav-item .dropdown-menu {
  background-color: white;
  width: 100%;
  text-align: center;
}

.custom-navbar .navbar-nav .nav-item .dropdown-menu a {
  color: black;
}

.custom-navbar .navbar-nav .nav-item .dropdown-menu a:hover {
  background-color: #0c1439;
  color: white;
}
/* END OF NAVIGATION */

/* SLIDER */
.carousel {
  background-size: cover;
  color: #ffffff;
  text-align: center;
  padding: 48px 0px 48px 0px;
}

.carousel img {
  max-width: 100%;
  height: auto;
}

.carousel-item img {
  max-width: 1300px;
  width: 100%;
  margin: auto;
}

.custom-carousel {
  max-width: 100%;
  height: auto;
}
/* END OF SLIDER */

/* PRODUCTS */
.images {
  background-color: #e5e5ec;
  height: 200px;
  width: 100%;
  padding: 5px 32px 5px 32px;
  transition: filter 0.3s;
}

.images:hover {
  filter: brightness(80%);
}

.productImage {
  width:  auto;
  background-color: black;
  margin: 5px;
  padding: 5px;
}

.section-title {
  color: #0c1439;
  font-size: 42px;
  font-weight: bold;
  margin-bottom: 20px;
}

.section-featured {
  background-color: #ec227b;
  text-align: center;
  width: 140px;
  font-size: 16px;
  color: #fff;
  font-weight: bold;
  padding: 10px;
  border-radius: 11px;
}
.btn-add-to-cart {
  width: 100%;
  border: #fbaf41;
  border-radius: 4px;
  font-weight: bold;
  background-color: #fbaf41;
  color: #fff;
  transition: background-color 0.3s;
  padding: 4px;
}

.productTitle {
  color: #15b3bd;
  font-weight: bold;
  font-size: 16px;
  height: 48px;
  transition: color 0.3s ease;
}

.productInclude {
  height: 48px;
  font-weight: 360;
  font-size: 16px;
  margin-top: 12px;
  overflow: hidden;
}

.productTitle:hover {
  color: #0c1439 !important;
}

.btn-add-to-cart:hover {
  background-color: #ff9900;
}
.btn-add-to-cart:active {
  background-color: #e18438;
}
@media (max-width: 1200px) {
  .images {
    padding: 5px 48px 5px 48px;
    height: 200px;
  }
}
@media (max-width: 768px) {
  .images {
    padding: 5px 48px 5px 48px;
    height: 200px;
  }
}

/* END OF PRODUCTS */

/* FOOTER */
.contentdoang {
  display: flex;
  flex-direction: column;
  min-height: 70vh;
}

.content-wrap {
  flex: 1;
}

.wrapper {
  background-color: #0c1439;
  padding: 20px;
}

.copyright {
  background-color: #0c1439;
  color: #ffffff;
  margin-top: 30px;
  bottom: 0;
  border-top: 1px solid #ffffff;
}

.footer {
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
}

.footer img {
  width: 280px;
}

.footer h2 {
  font-size: 24px;
  padding-bottom: 10px;
}

.footer p {
  text-align: left;
  margin: 0px;
}

.footer-section {
  text-align: center;
  color: #fff;
  position: relative;
}

.instagram-section,
.whatsapp-section,
.facebook-section {
  display: flex;
  align-items: center;
  text-align: left;
}

.footer-section img {
  width: 30px;
  margin-right: 8px;
}

@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    align-items: left;
    text-align: center;
  }

  .footer-section {
    margin-top: 15px;
    text-align: left;
  }
  .footer-section img {
    width: 32px;
  }
}
/* END OF FOOTER */

/* PRODUCT DETAILS */
.btn-order {
  width: 100%;
  display: inline-block;
  padding: 10px 20px;
  background-color: #fbaf41;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
  text-align: center;
  border-radius: 5px;
  border: none;
}
.btn-order:hover {
  filter: brightness(90%);
}
.btn-order:active {
  filter: brightness(80%);
}

.productdetailsImage {
  height: 600px;
  margin-left: 110px;
  overflow: hidden;
  background: #e5e5ec;
  display: flex;
  justify-content: center;
  align-items: center;

}

.productDetailsCard {
  height: 600px;
  overflow: hidden;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 8px;
  position: relative;
  margin-right: 110px;
}

.imageOption {
  margin-bottom: 12px;

}

.imageOptionBox {
  display: flex;
  justify-content: flex-start;
  margin-left: 110px;
  gap: 12px;
}

.optionImage {
  width: 100%;
  height: 100px;
  object-fit: contain;
  cursor: pointer;
  background: #e5e5ec;
  
}
@media (max-width: 768px) {
  .btn-order {
    width: 100%; 
  }

  .imagesproductdetails {
    height: auto;
  }

  .productdetailsImage {
    margin: 0 20px 0 20px;
    height: 400px;
  }
  .productDetailsCard {
    text-align: left; /* Menengahkan konten di dalam productDetailsCard */
    margin: 0 20px 0 20px;
  }
  .imageOptionBox {
    margin: 0 20px 0 20px;
  }
}

/* END OF PRODUCT DETAILS */

/* CART */
.imagescart {
  background-color: #e5e5ec;
  height: 200px;
  width: 100%;
  padding: 5px 20px 5px 20px;
  border-radius: 10px;
  transition: filter 0.3s;
}
.imagescart:hover {
  filter: brightness(80%);
}

.btn-removecart {
  width: 10%;
  padding: 6px;
  background-color: #ed227c;
  font-weight: bold;
  color: #fff;
  border-radius: 10px;
  border: none;
  bottom: 0;
  position: absolute;
  margin-bottom: 16px;
  right: 0;
  margin-right: 16px;
}
.btn-removecart:hover {
  filter: brightness(90%);
}
.btn-removecart:active {
  filter: brightness(80%);
}

.btn-clearcart {
  padding: 4px 8px 4px 8px;
  background-color: #ed227c;
  font-weight: 400;
  color: #fff;
  border-radius: 10px;
  border: none;
}
.btn-clearcart:hover {
  filter: brightness(90%);
}
.btn-clearcart:active {
  filter: brightness(80%);
}

.btn-ordercart {
  padding: 6px;
  background-color: #15b3bd;
  font-weight: 500;
  color: #fff;
  border-radius: 10px;
  border: none;
}
.btn-ordercart:hover {
  filter: brightness(90%);
}
.btn-ordercart:active {
  filter: brightness(80%);
}

.total-price {
  font-size: 24px; /* Atur ukuran font sesuai keinginan Anda */
  margin-top: 20px;
}

@media (max-width: 768px) {
  .btn-removecart {
    width: 100px; /* Mengatur lebar tombol secara otomatis */
    padding: 4px 8px; /* Memberikan padding agar tombol terlihat lebih rapi */
  }
  .imagescart {
    margin-bottom: 32px;
  }
  .price {
    margin-bottom: 42px;
  }
}
/* END OF CART */
